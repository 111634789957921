define("discourse/plugins/DiscourseCustomTimer/discourse/templates/actions", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    controller.hbs
  
  {{outlet}}
  
  */
  {
    "id": "flMTbRH7",
    "block": "[[[1,\"controller.hbs\\n\\n\"],[46,[28,[37,1],null,null],null,null,null],[1,\"\\n\"]],[],false,[\"component\",\"-outlet\"]]",
    "moduleName": "discourse/plugins/DiscourseCustomTimer/discourse/templates/actions.hbs",
    "isStrictMode": false
  });
});