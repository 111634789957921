define("discourse/plugins/DiscourseCustomTimer/initializers/discourse-custom-timer", ["exports", "discourse/lib/plugin-api", "discourse/models/topic-timer", "discourse/controllers/edit-topic-timer", "discourse/components/topic-timer-info", "@ember/object", "virtual-dom", "discourse/plugins/DiscourseCustomTimer/core/timer_time"], function (_exports, _pluginApi, _topicTimer, _editTopicTimer, _topicTimerInfo, _object, _virtualDom, _timer_time) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializeDiscourseCustomTimer(api) {
    // https://github.com/discourse/discourse/blob/master/app/assets/javascripts/discourse/lib/plugin-api.js.es6
    api.createWidget('timer-dropdown-item', {
      tagName: 'div.timer_dropdown_item',
      timeForDisplay(timerTime) {
        return (0, _virtualDom.h)('div.time-info-container', [(0, _virtualDom.h)('h3.time-header-label', timerTime.label), (0, _virtualDom.h)('p.time-detail-time', timerTime.formattedTime)]);
      },
      html(attrs) {
        return (0, _virtualDom.h)('button.widget-button.btn.timer-item', this.timeForDisplay(attrs.timerTime));
      },
      click() {
        this.attrs.onClick(this.attrs.timerTime.time);
      }
    });
    api.createWidget('timer-dropdown', {
      tagName: 'div.timer_dropdown_container',
      times: [_timer_time.TimerTimeFromNow.createWithHour('1h', 1), _timer_time.TimerTimeFromNow.createWithHour('2h', 3), _timer_time.TimerTimeFromNow.createWithHour('4h', 4), _timer_time.TimerTimeFromNow.createWithHour('6h', 6), _timer_time.TimerTimeWithSpecificMoment.createWithNextDaysAndHour('1d', 1, 10), _timer_time.TimerTimeWithSpecificMoment.createWithNextDaysAndHour('2d', 2, 10), _timer_time.TimerTimeWithSpecificMoment.createWithNextDaysAndHour('3d', 3, 10)],
      html(attrs) {
        console.log(this.times);
        return this.attach('menu-panel', {
          contents: () => this.times.map(time => this.attach("timer-dropdown-item", {
            timerTime: time,
            onClick: time => {
              attrs.setTimer(time).then(result => {
                this.clickOutside();
                this.updateTimerStatusUI(time, result);
              });
            }
          }))
        });
      },
      updateTimerStatusUI(time, result) {
        (0, _object.setProperties)("model.private_topic_timer", {
          execute_at: result.execute_at
        });
      },
      clickOutside() {
        this.sendWidgetAction('toggleTimerDropdown');
      }
    });
    api.decorateWidget('topic-admin-menu-button:after', function (helper) {
      function setTimer(time) {
        const REMINDER_TYPE = "reminder";
        const topicId = helper.attrs.topic.id;
        const basedOnLastPost = false;
        return _topicTimer.default.updateStatus(topicId, time, basedOnLastPost, REMINDER_TYPE);
      }
      const headerState = helper.widget.state;
      let contents = [];
      contents.push(helper.attach('button', {
        title: 'timer-dropdown',
        icon: 'far-clock',
        active: headerState.timerDropdownVisible,
        iconId: 'toggle-timer-dropdown',
        action: 'toggleTimerDropdown'
      }));
      if (headerState.timerDropdownVisible) {
        contents.push(helper.attach('timer-dropdown', {
          setTimer
        }));
      }
      return contents;
    });
    api.attachWidgetAction('topic-admin-menu-button', 'toggleTimerDropdown', function () {
      this.state.timerDropdownVisible = !this.state.timerDropdownVisible;
    });
  }
  var _default = _exports.default = {
    name: "discourse-custom-timer",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.31", initializeDiscourseCustomTimer);
    }
  };
});